<template>
  <div class="wrapper home">
    <div class="d-flex w-100">
      <div class="left">
        <div class="logo">
          <a>
            <img
              v-if="getAssets && getAssets.horizontalTeamLogo"
              :src="getAssets.horizontalTeamLogo"
              alt="Team-Logo"
            />
          </a>
        </div>
        <div class="main-heading">
          <h2>{{ translatedData?.homeTitle }}</h2>
          <a role="button" @click="typeSelection('Buying')" class="btn btn-red">
            <span>{{ translatedData?.continueButtonBuyingText }}</span>
          </a>
        </div>
      </div>
      <div class="right">
        <div class="nav-link" v-show="!getCreateAppFlag && !getCloneAppFlag">
          <router-link to="/sign-in" class="btn btn-white"
            ><span>{{ translatedData?.signInButtonText }}</span></router-link
          >
        </div>
        <div class="main-heading">
          <h2>{{ translatedData?.refinanceTitle }}</h2>
          <a
            role="button"
            @click="typeSelection('Refinance')"
            class="btn btn-white"
          >
            <span>{{ translatedData?.continueButtonRefinanceText }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const homePageJson = {
  homeTitle: "I’m buying a home",
  continueButtonBuyingText: "Continue",
  refinanceTitle: "I’m refinancing my home",
  continueButtonRefinanceText: "Continue",
  signInButtonText: "Sign-In",
};
import { mapActions, mapGetters } from "vuex";
import commonFunctions from "./../mixins/commonfunctions";
import CoPilotFeature from "./../mixins/salesforce-co-pilot"; //Logic for Salesforce Co-Pilot feature is in this mixin
import { translationMixin } from "../mixins/translationMixin";
export default {
  name: "Index",
  mixins: [commonFunctions, CoPilotFeature, translationMixin],
  data() {
    return {
      data: homePageJson,
    };
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getProspectId",
      "getAppFlow",
      "getUserId",
      "getAssets",
      "getQuestions",
      "getCreateAppFlag",
      "getCloneAppId",
      "getCloneAppFlag",
      "getApplications",
      "getActiveQuestion",
      "getIsCoPilot",
      "getSetupPassword",
      "getActivePage",
      "getCoPilotSession"
    ]),
  },
  mounted() {
    this.$nextTick(async () => {
      await this.getAppAssets();

      if (this.getOrgId && this.getProspectId) {
        if (
          this.getIsCoPilot &&
          (this.getCloneAppFlag || this.getCreateAppFlag)
        ) {
          this.fetchLoaderStatus(false);
        }

        if (!this.getIsCoPilot) {
          this.fetchLoaderStatus(false);
          if (!this.getCloneAppFlag && !this.getCreateAppFlag)
            await this.checkPasswordStatus();
        }
      }
    });
  },
  methods: {
    ...mapActions([
      "fetchUserId",
      "fetchUser",
      "fetchActiveQuestion",
      "fetchActiveSection",
      "fetchAppFlow",
      "fetchQuestions",
      "fetchSubQuestions",
      "fetchSections",
      "fetchLoaderStatus",
      "fetchCreateAppFlag",
      "fetchCloneAppId",
      "fetchCloneAppFlag",
      "updateApplications",
      "fetchForgotPassDetails",
      "updateQueObjectWithAnswer",
      "fetchActivePage",
    ]),

    typeSelection(appflow) {
      this.fetchActivePage(true);
      if (this.getCreateAppFlag) this.createNewApp(appflow);
      if (this.getCloneAppFlag) this.cloneTheApp(appflow);

      if (!this.getCreateAppFlag && !this.getCloneAppFlag) {
        if (this.getUserId) this.proceedToQuestionnaire(appflow);
        else this.enterTheApplication(appflow);
      }
    },

    proceedToQuestionnaire(appflow) {
      if (appflow === this.getAppFlow)
        this.$router.push({ path: "/questionnaire" });

      if (appflow !== this.getAppFlow) this.enterTheApplication(appflow);
    },

    async enterTheApplication(value) {
      this.fetchLoaderStatus(true);

      let user_id = this.getUserId || null;

      await this.$http
        .get(
          "/question/GetProspectQuestions/" +
            value +
            "/" +
            user_id +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchAppFlow(value);
            this.fetchSections(resp.sidebarSections);
            this.fetchQuestions(resp.appQuestions);
            this.fetchSubQuestions(resp.subQuestions);

            this.setActiveQueAndSection();

            this.$router.push({ path: "/questionnaire" });
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            console.log(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },

    // Create New Application or Clone Application code starts from here
    async cloneTheApp(type) {
      this.fetchLoaderStatus(true);

      let payLoad = {
        copyApplication: true,
        flowName: type,
        orgId: this.getOrgId,
        prospectId: this.getCloneAppId,
        isCopilotSession : this.getCoPilotSession
      };

      await this.$http
        .post("/account/createAndCopyDMAApplicationAPI", payLoad)
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.success(resp.message);
            this.updateApplications(resp.applications);
            this.fetchCloneAppFlag(false);
            this.fetchCloneAppId("");

            await this.continueWithApp(resp.applications[0]);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },

    async createNewApp(type) {
      this.fetchLoaderStatus(true);

      let payLoad = {
        copyApplication: false,
        flowName: type,
        orgId: this.getOrgId,
        prospectId: this.getApplications[0].prospectId,
        isCopilotSession : this.getCoPilotSession
      };

      await this.$http
        .post("/account/createAndCopyDMAApplicationAPI", payLoad)
        .then(async (response) => {
          this.fetchLoaderStatus(true);
          let resp = response.data;

          if (resp.response === "Success") {
            this.success(resp.message);
            this.updateApplications(resp.applications);
            this.fetchCreateAppFlag(false);

            await this.continueWithApp(resp.applications[0]);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },

    async continueWithApp(app) {
      if (app.type !== this.getAppFlow) await this.getQuestionnaire(app);

      await this.$http
        .get(
          "/account/getSingleQuestionAnswer?prospectId=" +
            app.prospectId +
            "&orgId=" +
            this.getOrgId +
            "&flowName=" +
            app.type
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchUserId(resp.prospectId);
            this.fetchUser(resp.prospectdetails);
            this.updateQueObjectWithAnswer(resp.appQuestions);

            this.setActiveQueAndSection();

            this.$router.push("/questionnaire");
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },

    async getQuestionnaire(app) {
      this.fetchAppFlow(app.type);

      await this.$http
        .get(
          "/question/GetProspectQuestions/" +
            this.getAppFlow +
            "/" +
            app.prospectId +
            "?orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchSections(resp.sidebarSections);
            this.fetchQuestions(resp.appQuestions);
            this.fetchSubQuestions(resp.subQuestions);

            this.setActiveQueAndSection();
          }

          if (resp.response === "Error") this.err(resp.message);
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
        });
    },
    // Create New Application or Clone Application code ends from here

    setActiveQueAndSection() {
      this.fetchActiveQuestion(1);

      let getSectionName = this.getQuestions.find(
        (v) => v.questionIndex === this.getActiveQuestion
      );

      this.fetchActiveSection(getSectionName.sectionName);
    },

    async checkPasswordStatus() {
      this.fetchLoaderStatus(true);

      await this.$http
        .get(
          "/account/AppDetailAPI?orgId=" +
            this.getOrgId +
            "&recordId=" +
            this.getProspectId
        )
        .then(async (response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            if (resp.assetData.appStarted && this.getSetupPassword)
              await this.setPassword(resp.assetData.userEmail);
            else this.fetchLoaderStatus(false);
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            console.log(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },

    async setPassword(email) {
      await this.$http
        .get(
          "/account/forgotPassword?emailOrPhone=" +
            email +
            "&msgOrEmail=" +
            "email" +
            "&orgId=" +
            this.getOrgId
        )
        .then((response) => {
          let resp = response.data;

          if (resp.response === "Success") {
            this.fetchForgotPassDetails(resp.prospectDetail);
            this.success(resp.message);
            this.$router.push({ path: "/reset-password" });
          }

          if (resp.response === "Error") {
            this.err(resp.message);
            this.fetchLoaderStatus(false);
          }
        })
        .catch((error) => {
          this.err(error);
          console.log(error);
          this.fetchLoaderStatus(false);
        });
    },
  },
};
</script>